@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

:root {
    --primary: #ffb400;
    --secondary: #34AD54;
    --light: #EEF9FF;
    --dark: #091E3E;

    --bs-primary-rgb: 255, 180, 0;
}

.btn-primary{
    --bs-btn-color: #fff;
    --bs-btn-bg: #ffb400;
    --bs-btn-border-color: #ffb400;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #eca706;
    --bs-btn-hover-border-color: #eca706;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #eca706;
    --bs-btn-active-border-color: #eca706;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #ffb400;
    --bs-btn-disabled-border-color: #ffb400;
}

.btn-outline-primary {
  --bs-btn-color: #ffb400;
  --bs-btn-border-color: #ffb400;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ffb400;
  --bs-btn-hover-border-color: #ffb400;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ffb400;
  --bs-btn-active-border-color: #ffb400;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffb400;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffb400;
  --bs-gradient: none;
}



/*** Section Title ***/
.section-title::before {
    position: absolute;
    content: "";
    width: 150px;
    height: 5px;
    left: 0;
    bottom: 0;
    background: var(--primary);
    border-radius: 2px;
}

.section-title.text-center::before {
    left: 50%;
    margin-left: -75px;
}

.section-title.section-title-sm::before {
    width: 90px;
    height: 3px;
}

.section-title::after {
    position: absolute;
    content: "";
    width: 6px;
    height: 5px;
    bottom: 0px;
    background: #FFFFFF;
    -webkit-animation: section-title-run 5s infinite linear;
    animation: section-title-run 5s infinite linear;
}

.section-title.section-title-sm::after {
    width: 4px;
    height: 3px;
}

.section-title.text-center::after {
    -webkit-animation: section-title-run-center 5s infinite linear;
    animation: section-title-run-center 5s infinite linear;
}

.section-title.section-title-sm::after {
    -webkit-animation: section-title-run-sm 5s infinite linear;
    animation: section-title-run-sm 5s infinite linear;
}

@keyframes section-title-run {
    0% {left: 0; } 50% { left : 145px; } 100% { left: 0; }
}

@keyframes section-title-run-center {
    0% { left: 50%; margin-left: -75px; } 50% { left : 50%; margin-left: 45px; } 100% { left: 50%; margin-left: -75px; }
}

@keyframes section-title-run-sm {
    0% {left: 0; } 50% { left : 85px; } 100% { left: 0; }
}

.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  
  .poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  
  .poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
  }
  
  .poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  
  .poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
  }
  
  .poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
  
  .form-control:focus{
    border-color: #ffb400;
    box-shadow: 0 0 0 .25rem #ffb40025;

  }
  
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff00;
}

::-webkit-scrollbar-thumb {
  background: #ffb400;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffb400;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
